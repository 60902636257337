import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import '@assets/sharedStyles/formItems.scss';
import { useConfirmationDialog } from '@src/components/shared/ConfirmationPopup/ConfirmationDialog';
import { addLocalOffsetToDateObject } from '@src/utils/date.helpers';
import { REQUIRED_INPUT_MESSAGE } from '@src/utils/formRules/formRules';
import { axios_delete, axios_patch } from '@utils/rest_axios';
import { Button, DatePicker, Form, Input, message } from 'antd';
import moment from 'moment';
import { NumericFormat } from 'react-number-format';
import './UpdateModuleControlPrice.scss';

const UpdateModuleControlPriceAdmin = ({
  module_control,
  callBack,
  reload
}) => {
  const [form] = Form.useForm();

  const { getConfirmation } = useConfirmationDialog();

  const onFinish = async (values) => {
    const priceBeginningWithTime = addLocalOffsetToDateObject(
      values.priceBeginning
    );
    const priceBeginningEpochWithOffset = priceBeginningWithTime.valueOf();

    const data = {
      // eslint-disable-next-line no-useless-escape
      price: String(values.price).replace(/\./g, '').replace(/,/g, '.'),
      priceBeginning: priceBeginningEpochWithOffset
    };

    if (
      module_control.price.toString() === values.price &&
      values.priceBeginning === module_control.priceBeginning
    ) {
      message.warning('Değişiklik Bulunmuyor!', 5);
    } else {
      try {
        // handle data about error on above
        const moduleControlRes = await axios_patch(
          `/admin/module-controls/${module_control.moduleControlId}`,
          {
            price: +data.price,
            priceBeginning: data.priceBeginning
          }
        );

        if (moduleControlRes.status === 200) {
          callBack(!reload);
          message.success('Güncelleme Başarılı!', 5);
        }
      } catch ({ response }) {
        message.error('Güncelleme Başarısız!', 5);
      }
    }
  };

  const handleDelete = async () => {
    const confirmed = await getConfirmation({
      title: 'Fiyat aralığını silmek istediğinizden emin misiniz?',
      message: '',
      cancelText: 'Vazgeç',
      confirmText: 'Onayla'
    });
    if (confirmed) {
      try {
        await axios_delete(
          '/admin/module-controls/',
          module_control.moduleControlId
        );
        callBack(!reload);
        message.success('Fİyat Silme Başarılı!', 5);
      } catch ({ response }) {
        message.error('Fiyat Silinemedi', 5);
      }
    }
  };

  return (
    <div>
      <Form
        initialValues={{
          price: module_control.price,
          priceBeginning: moment(module_control.priceBeginning)
        }}
        form={form}
        layout={'inline'}
        name="update-module-control-name"
        onFinish={onFinish}
        colon={true}
      >
        <div className="updateModuleControlPrice">
          <div className="updateModuleControlPrice__price">
            <Form.Item
              label="Fiyatı"
              name="price"
              rules={[
                {
                  required: true,
                  message: REQUIRED_INPUT_MESSAGE
                }
              ]}
            >
              <NumericFormat
                customInput={Input}
                thousandSeparator="."
                decimalSeparator=","
                decimalScale="2"
              />
            </Form.Item>

            <span className="updateModuleControlPrice_date_label_align">
              Başlangıç Tarihi:
            </span>
            <Form.Item name="priceBeginning">
              <DatePicker className="kemi_form_input" format={'DD.MM.YYYY'} />
            </Form.Item>

            <Form.Item>
              <Button
                className="updateModuleControlPrice__moreButton__button kemi_form_btn_purple form_btn_margin"
                shape="round"
                htmlType="submit"
              >
                <EditOutlined />
                Güncelle
              </Button>
            </Form.Item>

            <Form.Item>
              <Button
                className="updateModuleControlPrice__moreButton__button kemi_form_btn_purple form_btn_margin"
                shape="round"
                danger
                type="secondary"
                onClick={handleDelete}
              >
                <DeleteOutlined />
                Aralığı Sil
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default UpdateModuleControlPriceAdmin;
