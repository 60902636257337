// For option mapping in request filter pages
export const statuses = [
  { id: 1, status: 'ACCEPTED', text: 'Ödendi' },
  { id: 2, status: 'REJECTED', text: 'Reddedildi' },
  { id: 3, status: 'PENDING', text: 'Doğrulandı' },
  { id: 4, status: 'PROBLEM', text: 'Kontrol Gerekli' },
];
// for listing requests and detail card
export const statusMap = new Map([
  ['PENDING', 'Doğrulandı'],
  ['ACCEPTED', 'Ödendi'],
  ['REJECTED', 'Reddedildi'],
  ['PROBLEM', 'Kontrol Gerekli'],
]);
