import StocklossActiveRequests from '@Components/company_components/pharmacy_reqs/StocklossRequests/StocklossActiveRequests';
import '@assets/sharedStyles/pageItems.scss';

const PharmacyRequests = ({ type }) => {
  return (
    <div className="kemi_pages_tabs">
      <StocklossActiveRequests type={type} />
    </div>
  );
};

export default PharmacyRequests;
