import { CloseOutlined } from '@ant-design/icons';
import { Row } from 'antd';
import React from 'react';
import './ConfirmationDialog.scss';

const ConfirmationDialog = ({
  open,
  title,
  cancelText,
  confirmText,
  message,
  onConfirm,
  onDismiss,
}) => {
  return (
    <>
      {open && (
        <div className="confirmation__container">
          <div className="confirmation_popup_container">
            <div className="confirmation_popup_headline">
              <Row justify="end">
                <div className="close-btn" onClick={onDismiss}>
                  <CloseOutlined />
                </div>
              </Row>
            </div>

            <Row justify="center">
              <h3 className="popup__title">{title}</h3>
            </Row>

            <Row justify="center">
              <p className="popup__message">{message}</p>
            </Row>

            <Row justify="space-around">
              {cancelText ? (
                <button
                  className="popup__btn popup__btn_cancel"
                  onClick={onDismiss}
                >
                  {cancelText}
                </button>
              ) : (
                ''
              )}
              {confirmText ? (
                <button
                  className="popup__btn popup__btn_confirm"
                  onClick={onConfirm}
                >
                  {confirmText}
                </button>
              ) : (
                ''
              )}
            </Row>
          </div>
        </div>
      )}
    </>
  );
};

const ConfirmationDialogContext = React.createContext({});

const ConfirmationDialogProvider = ({ children }) => {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogConfig, setDialogConfig] = React.useState({});

  const openDialog = ({
    title,
    message,
    cancelText,
    confirmText,
    actionCallback,
  }) => {
    setDialogOpen(true);
    setDialogConfig({
      title,
      message,
      cancelText,
      confirmText,
      actionCallback,
    });
  };

  const resetDialog = () => {
    setDialogOpen(false);
    setDialogConfig({});
  };

  const onConfirm = () => {
    resetDialog();
    dialogConfig.actionCallback(true);
  };

  const onDismiss = () => {
    resetDialog();
    dialogConfig.actionCallback(false);
  };

  return (
    <ConfirmationDialogContext.Provider value={{ openDialog }}>
      <ConfirmationDialog
        open={dialogOpen}
        title={dialogConfig?.title}
        message={dialogConfig?.message}
        cancelText={dialogConfig?.cancelText}
        confirmText={dialogConfig?.confirmText}
        onConfirm={onConfirm}
        onDismiss={onDismiss}
      />
      {children}
    </ConfirmationDialogContext.Provider>
  );
};

const useConfirmationDialog = () => {
  const { openDialog } = React.useContext(ConfirmationDialogContext);

  const getConfirmation = ({ ...options }) =>
    new Promise((res) => {
      openDialog({ actionCallback: res, ...options });
    });

  return { getConfirmation };
};

export default ConfirmationDialog;
export { ConfirmationDialogProvider, useConfirmationDialog };
