import PublicLayout from '@src/layouts/PublicLayout';
import AdminDashboard from '@src/pages/Admin/AdminDashboard/AdminDashboard';
import LoginPage from '@src/pages/Auth/LoginPage/LoginPage';
import Kki from '@src/pages/Company/Kki/Kki';
import ManageWarehouse from '@src/pages/Company/ManageWarehouse/ManageWarehouse';
import PharmacyRequests from '@src/pages/Company/PharmacyStocklossRequests/PharmacyRequests';
import Stockloss from '@src/pages/Company/Stockloss/Stockloss';
import PharmacyDashboard from '@src/pages/Pharmacy/PharmacyDashboard/PharmacyDashboard';
//import PharmacyNewRequest from '@src/pages/Pharmacy/PharmacyNewRequest/PharmacyNewRequest';

import Dashboard from '@src/pages/Company/Dashboard/Dashboard';
import Notifications from '@src/pages/Company/Notifications/Notifications';
import PharmacyKKIRequests from '@src/pages/Company/PharmacyKKIRequests/PharmacyKKIRequests';
//import AdminPharmacyRequests from '@src/pages/Admin/AdminStockRequestsPage/AdminStockRequestsPage';

import AdminLayout from '@src/layouts/AdminLayout';
import HandleFailingRequests from '@src/pages/Admin/AdminFailingRequests/HandleFailingRequests';
import AdminUserDashboard from '@src/pages/Admin/AdminNewUser/AdminUserDashboard';
import AdminItsQueries from '@src/pages/Admin/AdminTrackerQueries/AdminTrackerQueries';
import AdminUserActions from '@src/pages/Admin/AdminUserActions/AdminUserActions';
import AdminCompanyModule from '@src/pages/Admin/BusinessManagement/AdminCompanyModule/AdminCompanyModule';
import AdminKKIRequestsPage from '@src/pages/Admin/BusinessManagement/AdminKKIRequestsPage/AdminKKIRequestsPage';
import AdminStockRequestsPage from '@src/pages/Admin/BusinessManagement/AdminStockRequestsPage/AdminStockRequestsPage';
import AdminNotifications from '@src/pages/Company/Notifications/AdminNotifications';

import PharmacyInfoGuide from '@src/pages/Pharmacy/PharmacyInfoPages/PharmacyInfoGuide';
import { PharmacyInfoQA } from '@src/pages/Pharmacy/PharmacyInfoPages/PharmacyInfoQA';
import PharmacyNotifications from '@src/pages/Pharmacy/PharmacyNotifications/PharmacyNotifications';
import PharmacyProfile from '@src/pages/Pharmacy/PharmacyProfile/PharmacyProfile';
import FakePharmacyDashboard from '@src/pages/PharmacyTour/FakePharmacyDashboard/FakePharmacyDashboard';

import CompanyLayout from '@src/layouts/CompanyLayout';
import PharmacyLayout from '@src/layouts/PharmacyLayout';
import Admin from '@src/pages/Admin/UserManagement/Admin/Admin';
import Company from '@src/pages/Admin/UserManagement/Company/Company';
import Pharmacy from '@src/pages/Admin/UserManagement/Pharmacy/Pharmacy';
import ResetPasswordForm from '@src/pages/Auth/ResetPassword/ResetPasswordForm';
import PapModule from '@src/pages/Company/PapModule/PapModule';
import Logout from '@src/pages/Pharmacy/Logout/Logout';
import Redirect from '@src/pages/Pharmacy/Redirect/Redirect';
import ContractPage from '@src/pages/PublicPages/ContractPage';

export const publicPagesData = [
  {
    // Public Pages
    path: '',
    element: <LoginPage />,
    title: 'publicIndex',
    for: 'public'
  },
  {
    path: 'verify-account',
    element: <LoginPage />,
    title: 'verifyAccount',
    for: 'public'
  },
  {
    path: 'passwordReset',
    element: (
      <PublicLayout>
        <ResetPasswordForm />
      </PublicLayout>
    ),
    title: 'passwordReset',
    for: 'public'
  },
  {
    path: 'uyelik-sozlesmesi',
    element: <ContractPage />,
    title: 'uyelik-sozlesmesi',
    for: 'public'
  },

  {
    path: 'kvkk-metni',
    element: <ContractPage activeTabKey="1" />,
    title: 'kvkk-metni',
    for: 'public'
  },
  {
    path: 'acık-riza-metni',
    element: (
      <PublicLayout>
        <ContractPage activeTabKey="2" />
      </PublicLayout>
    ),
    title: 'acık-riza-metni',
    for: 'public'
  },
  {
    path: 'cerez-politikasi',
    element: (
      <PublicLayout>
        <ContractPage activeTabKey="3" />
      </PublicLayout>
    ),
    title: 'cerez-politikasi',
    for: 'public'
  }
];

export const companyPagesData = [
  {
    path: '',
    element: (
      <CompanyLayout>
        <Dashboard />
      </CompanyLayout>
    ),
    title: 'companyIndex',
    for: 'Company'
  },
  {
    path: 'anasayfa',
    element: (
      <CompanyLayout>
        <Dashboard />
      </CompanyLayout>
    ),
    title: 'companyDashboard',
    for: 'Company'
  },
  {
    path: 'stokzarari-kayitlarim',
    element: (
      <CompanyLayout>
        <Stockloss />
      </CompanyLayout>
    ),
    title: 'stokzarari-kayitlarim',
    for: 'Company'
  },
  {
    path: 'stokzarari-eczanebasvurulari',
    element: (
      <CompanyLayout>
        <PharmacyRequests type="STOCK" />
      </CompanyLayout>
    ),
    title: 'stokzarari-eczanebasvurulari',
    for: 'Company'
  },
  {
    path: 'depo-yonetimi',
    element: (
      <CompanyLayout>
        <ManageWarehouse />
      </CompanyLayout>
    ),
    title: 'depo-yonetimi',
    for: 'Company'
  },
  {
    path: 'kki-kayitlarim',
    element: (
      <CompanyLayout>
        <Kki />
      </CompanyLayout>
    ),
    title: 'kki-kayitlarim',
    for: 'Company'
  },
  {
    path: 'kki-eczanebasvurulari',
    element: (
      <CompanyLayout>
        <PharmacyKKIRequests />
      </CompanyLayout>
    ),
    title: 'kki-eczanebasvurulari',
    for: 'Company'
  },
  {
    path: 'pap-kayitlarim',
    element: (
      <CompanyLayout>
        <PapModule />
      </CompanyLayout>
    ),
    title: 'pap-kayitlarim',
    for: 'Company'
  },
  {
    path: 'pap-eczanebasvurulari',
    element: (
      <CompanyLayout>
        <PharmacyRequests type="PAP" />
      </CompanyLayout>
    ),
    title: 'pap-eczanebasvurulari',
    for: 'Company'
  },

  {
    path: 'duyurular',
    element: (
      <CompanyLayout>
        <Notifications />
      </CompanyLayout>
    ),
    title: 'duyurular',
    for: 'Company'
  }
];

export const pharmacyRedirectPagesData = [
  {
    path: 'logout',
    element: <Logout />,
    title: 'pharmacyLogout',
    for: 'Pharmacist'
  },
  {
    path: 'redirect',
    element: <Redirect />,
    title: 'pharmacyRedirect',
    for: 'Pharmacist'
  },
  {
    path: '',
    element: <Redirect />,
    title: 'pharmacyRedirect',
    for: 'Pharmacist'
  }
];

export const pharmacyPagesData = [
  {
    path: '',
    element: (
      <PharmacyLayout>
        <PharmacyDashboard />
      </PharmacyLayout>
    ),
    title: 'pharmacyIndex',
    for: 'Pharmacist'
  },
  {
    path: 'basvurular',
    element: (
      <PharmacyLayout>
        <PharmacyDashboard />
      </PharmacyLayout>
    ),
    title: 'pharmacyDashboard',
    for: 'Pharmacist'
  },
  {
    path: 'eczane-duyurular',
    element: (
      <PharmacyLayout>
        <PharmacyNotifications />
      </PharmacyLayout>
    ),
    title: 'eczane-duyurular',
    for: 'Pharmacist'
  },
  {
    path: 'ayarlar',
    element: (
      <PharmacyLayout>
        <PharmacyProfile />
      </PharmacyLayout>
    ),
    title: 'ayarlar',
    for: 'Pharmacist'
  },
  {
    path: 'rehber',
    element: (
      <PharmacyLayout>
        <FakePharmacyDashboard />
      </PharmacyLayout>
    ),
    title: 'rehber',
    for: 'Pharmacist'
  },
  {
    path: 'qa',
    element: (
      <PharmacyLayout>
        <PharmacyInfoQA />
      </PharmacyLayout>
    ),
    title: 'qa',
    for: 'Pharmacist'
  },
  {
    path: 'guide',
    element: (
      <PharmacyLayout>
        <PharmacyInfoGuide />
      </PharmacyLayout>
    ),
    title: 'guide',
    for: 'Pharmacist'
  },
  {
    path: 'uyelik-sozlesmesi',
    element: <ContractPage />,
    title: 'uyelik-sozlesmesi',
    for: 'Pharmacist'
  },
  {
    path: 'kvkk-metni',
    element: <ContractPage />,
    title: 'kvkk-metni',
    for: 'Pharmacist'
  }
];

export const adminPagesData = [
  //   NEW ADMIN PAGES
  {
    path: '/user-management-pharmacy',
    element: (
      <AdminLayout>
        <Pharmacy />
      </AdminLayout>
    ),
    title: 'user-management-pharmacy',
    for: 'Admin'
  },
  {
    path: '/user-management-company',
    element: (
      <AdminLayout>
        <Company />
      </AdminLayout>
    ),
    title: 'user-management-company',
    for: 'Admin'
  },
  {
    path: '/user-management-admin',
    element: (
      <AdminLayout>
        <Admin />
      </AdminLayout>
    ),
    title: 'user-management-admin',
    for: 'Admin'
  },
  //   NEW ADMIN PAGES
  {
    path: '',
    element: (
      <AdminLayout>
        <AdminDashboard />
      </AdminLayout>
    ),
    title: 'adminIndex',
    for: 'Admin'
  },
  {
    path: 'admin-dashboard',
    element: (
      <AdminLayout>
        <AdminDashboard />
      </AdminLayout>
    ),
    title: 'admin-dashboard',
    for: 'Admin'
  },
  {
    path: 'admin-company',
    element: (
      <AdminLayout>
        <AdminCompanyModule />
      </AdminLayout>
    ),
    title: 'admin-company',
    for: 'Admin'
  },
  {
    path: 'admin-stock-requests',
    element: (
      <AdminLayout>
        <AdminStockRequestsPage moduleType={'STOCK'} />
      </AdminLayout>
    ),
    title: 'admin-stock-requests',
    for: 'Admin'
  },
  {
    path: 'admin-kki-requests',
    element: (
      <AdminLayout>
        <AdminKKIRequestsPage moduleType={'KKI'} />
      </AdminLayout>
    ),
    title: 'admin-kki-requests',
    for: 'Admin'
  },
  {
    path: 'admin-pap-requests',
    element: (
      <AdminLayout>
        <AdminStockRequestsPage moduleType={'PAP'} />
      </AdminLayout>
    ),
    title: 'admin-pap-requests',
    for: 'Admin'
  },
  {
    path: 'admin-new-user',
    element: (
      <AdminLayout>
        <AdminUserDashboard />
      </AdminLayout>
    ),
    title: 'admin-new-user',
    for: 'Admin'
  },
  {
    path: 'admin-user-activity',
    element: (
      <AdminLayout>
        <AdminUserActions />
      </AdminLayout>
    ),
    title: 'admin-user-activity',
    for: 'Admin'
  },
  {
    path: 'admin-its-queries',
    element: (
      <AdminLayout>
        <AdminItsQueries />
      </AdminLayout>
    ),
    title: 'admin-its-queries',
    for: 'Admin'
  },
  {
    path: 'admin-failing-requests',
    element: (
      <AdminLayout>
        <HandleFailingRequests />
      </AdminLayout>
    ),
    title: 'admin-failing-requests',
    for: 'Admin'
  },
  {
    path: 'admin-notifications',
    element: (
      <AdminLayout>
        <AdminNotifications />
      </AdminLayout>
    ),
    title: 'admin-notifications',
    for: 'Admin'
  }
  // // Not Found Pages -------------------------------------------
  // {
  //   path: '*',
  //   element: (
  //     <CompanyLayout>
  //       <NotFound />
  //     </CompanyLayout>
  //   ),
  //   title: 'not-found',
  //   for: 'Company'
  // }
];
