import { Badge, Layout, Menu } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { AiFillNotification } from 'react-icons/ai';
import { FaCubes } from 'react-icons/fa';
import { IoSettingsSharp } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import kemi_logo from '../../../assets/img/kemi_light_logo.png';
import { PharmacyNotificationContext } from '../PharmacyNotificationContextProvider/PharmacyNotificationContextProvider';
const { Sider } = Layout;

function PharmacyNavbar() {
  const { user } = useSelector((state) => state.auth);
  const [collapsed, setCollapsed] = useState(window.screen.width <= 1600);

  const notifications = useContext(PharmacyNotificationContext);
  const [unread, setUnread] = useState([]);

  const [openKeys, setOpenKeys] = useState([]);
  const handleOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  useEffect(() => {
    function calcUnreadNotifications() {
      const filteredUnread = notifications.notifications.filter(
        (notification) => {
          return !notification.isRead && notification;
        }
      );
      setUnread(filteredUnread.length);
    }
    if (notifications.notifications.length > 0) {
      calcUnreadNotifications();
    }
  }, [notifications]);

  const menuItems = [
    {
      key: '/basvurular',
      icon: <FaCubes size={26} className="sidebar__menu-icon" />,
      label: <Link to="/basvurular">Başvurularım</Link>
    },
    {
      key: '/eczane-duyurular',
      icon: <AiFillNotification size={26} className="sidebar__menu-icon" />,
      label: (
        <>
          {' '}
          <Link to="/eczane-duyurular">Duyurular</Link>{' '}
          <Badge
            style={{ position: 'absolute', right: '-70px', top: '-10px' }}
            count={unread}
          />
        </>
      )
    },
    {
      key: '/ayarlar',
      icon: <IoSettingsSharp size={26} className="sidebar__menu-icon" />,
      label: <Link to="/ayarlar">Ayarlar</Link>
    }
  ];

  return (
    <Sider
      onBreakpoint={(broken) =>
        broken ? setCollapsed(true) : setCollapsed(false)
      }
      className="sidebar__"
      theme="light"
      width="15%"
      breakpoint="xxl"
      trigger={null}
      collapsed={collapsed}
    >
      {collapsed ? null : (
        <>
          <div className="sidebar__brand">
            <a href="/">
              <img src={kemi_logo} style={{ width: '35%' }} alt="navbar-logo" />
            </a>
          </div>
          <div className="__user_information">
            <h3>{user?.name}</h3>
          </div>
        </>
      )}

      <Menu
        className="sidebar__menu"
        openKeys={openKeys}
        onOpenChange={handleOpenChange}
        selectedKeys={window.location.pathname}
        items={menuItems}
        mode="inline"
      />
    </Sider>
  );
}

export default PharmacyNavbar;
